import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import {brandColor2, gray4} from "../styles/Colors";
import HeroTitleOutline from "../HeroOutlineTitle";
import HeroDivideRight from "../HeroDivideRight";
import StackAgencyLanding from "../publishers/StackAgencyLanding";
import TextHighlight from "../styles/TextHighlight";

const PublishersStackAgencyStatic = () => {
  const images = useStaticQuery(graphql`
    query {
      landingDesktop: file(relativePath: { eq: "27-stackads-desktop.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      landingMedia: file(relativePath: { eq: "27-stackads-mobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 911, maxHeight: 1012, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      file(relativePath: { eq: "stackagency/Iphone_12_RS_content.png" }) {
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <>
      <StackAgencyLanding image={images.file}/>

      <HeroTitleOutline
        theme={{
          background: gray4,
          fill: gray4,
          paddingBottom: "0px",
          width: "380px",
          marginTop: "-100px"
        }}
        title="ONE-STOP"
        subtitle="SHOP"
      />

      <HeroDivideRight
        theme={{background: gray4, lines: brandColor2}}
        contentTag={"div"}
      >
        <p>
          We connect you with top brands that have pre-approved content and performance marketing budgets—spend that
          would otherwise be inaccessible. Brands come to us looking for content opportunities.
        </p>
        <p>
          <TextHighlight theme={{background: gray4}}>
            StackAgency’s experts
          </TextHighlight>{" "}
           become an extension of your business development team, providing complete project
          management support from concept to completion, including monthly strategy planning sessions. You’ll be able to
          scale incremental revenue while maintaining a seamless reader experience with your team writing the content.
        </p>
      </HeroDivideRight>
    </>
  );
};

export default PublishersStackAgencyStatic;
