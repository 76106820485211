import React from "react";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";
import loadable from "@loadable/component";
import LazyLoadSection from "../../components/LazyLoadSection";
import StaticSection from "../../components/sections/PublishersStackAgencyStatic";

const AsyncSection = loadable(() =>
    import("../../components/sections/PublishersStackAgencyDefer")
);

const StackAgency = () => (
    <Layout>
        <Seo
            title="StackAgency for Publishers"
            description="Connecting brands with readers through content marketing"
        />

        <LazyLoadSection static={<StaticSection />}>
            <AsyncSection />
        </LazyLoadSection>
    </Layout>
);

export default StackAgency;
