import React, {PureComponent} from "react";
import MainHeroTitle from "../MainHeroTitle";
import {gray4, white} from "../styles/Colors";
import Container from "../styles/Container";
import styled from "styled-components";
import {mediaDesktop, mediaMedium} from "../styles/variables";
import Image from "../Image";
import {Eyebrows20} from "../styles/Eyebrows";
import OutlinedText from "../styles/OutlinedText";
import {Headline80} from "../styles/Headings";
import uniqueId from "lodash/uniqueId";
import {Linear, TimelineMax, TweenLite} from "gsap/dist/gsap";
import {ScrollMagicPluginGsap} from "scrollmagic-plugin-gsap";

const ContainerRelative = styled(Container)`
  position: relative;
  padding-top: 43px;

  @media (${mediaMedium}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 98px;
    padding-bottom: 240px;
  }

  @media (${mediaDesktop}) {
    padding-top: 38px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  max-width: 100vw;
  background-color: ${white};
  overflow: hidden;
  padding-bottom: 0;

  @media (${mediaMedium}) {
    padding-bottom: 0;
  }
`;

const Column = styled.div`
  position: relative;
  z-index: 2;
`;

const DisplayWrapper = styled.div`
  margin-top: 64px;
  width: 100%;

  @media (${mediaMedium}) {
    margin-top: 0;
    position: absolute;
    right: -30%;
    top: -13%;
    width: 500px;
    transform: none;
  }

  @media (${mediaDesktop}) {
    right: -30%;
  }

  @media (min-width: 980px) {
    width: 700px;
  }
`;

const Display = styled(Image)`
  margin: 0 auto;

  @media (${mediaMedium}) {
    margin: 0;
    transform: none;
    max-width: none !important;
    max-height: none !important;
  }
`;

const Bottom = styled.div`
  background-color: ${gray4};
  margin-top: -20%;
  position: relative;
  z-index: 1;

  @media (min-width: 720px) {
    margin-top: 0;
  }

  @media (${mediaMedium}) {
    position: static;
    z-index: initial;
    margin: 0;
  }
`;

const Space = styled.div`
  padding-top: 0;
  padding-bottom: 93px;

  @media (${mediaMedium}) {
    display: block;
    width: 100%;
    padding-top: 104px;
  }
`;

const SubTitle = styled(Eyebrows20)`
  margin: 0 0 28px;
`;

const Trigger = styled.div`
  position: absolute;
  bottom: 10%;

  @media (${mediaMedium}) {
    bottom: 0;
  }
`;

class StackAgencyLanding extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      controllerSM: null, sceneSM: null, id: uniqueId("lading-")
    };
  }

  async componentDidMount() {
    this.ScrollMagic = await import("scrollmagic");
    TweenLite.defaultOverwrite = "none";

    ScrollMagicPluginGsap(this.ScrollMagic, TweenLite, TimelineMax);

    this.initAnimation();
  }

  componentWillUnmount() {
    const {controllerSM, sceneSM} = this.state;

    if (controllerSM) controllerSM.destroy(true);
    if (sceneSM) sceneSM.destroy(true);
  }

  initAnimation() {
    const TopToVars = {
      translateY: "-40%", ease: Linear.easeIn
    };

    const tweenSM = TweenLite.to(`#${this.state.id}-display`, 1, TopToVars);

    // init controller
    const controllerSM = new this.ScrollMagic.Controller();

    // build scene
    const sceneSM = new this.ScrollMagic.Scene({
      triggerElement: `#${this.state.id}-trigger-top`, duration: 500, offset: -200
    })
      .setTween(tweenSM)
      .addTo(controllerSM);

    this.setState({controllerSM, sceneSM});
  }

  render() {
    return (<>
      <Wrapper>
        <ContainerRelative>
          <Trigger id={`${this.state.id}-trigger-top`}/>

          <div>
            <SubTitle as="span">STACKAGENCY FOR PUBLISHERS</SubTitle>
            <MainHeroTitle
              link={{
                href: "mailto:agency@stackcommerce.com?subject=STACKAGENCY%20For%20Publishers",
                as: 'a'
              }}
              label="Get Started"
            >
              CONNECTING BRANDS WITH{" "}
              <OutlinedText component={Headline80} as="span">
                READERS THROUGH CONTENT MARKETING
              </OutlinedText>
            </MainHeroTitle>
          </div>

          <Column id={`${this.state.id}-display`}>
            <DisplayWrapper>
              <Display fluid={this.props.image.childImageSharp.fluid}/>
            </DisplayWrapper>
          </Column>
        </ContainerRelative>
        <Bottom>
          <Container>
            <Space/>
          </Container>
        </Bottom>
      </Wrapper>
    </>);
  }
}

export default StackAgencyLanding;
